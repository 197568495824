import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import "@fontsource/bebas-neue"; // Importing Bebas Neue font

// Define fade-in and bounce animation
const fadeInBounce = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px) scale(0.8);
  }
  50% {
    opacity: 1;
    transform: translateY(-20px) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
`;

const PositionedTextBox = styled.div`
  position: absolute;
  text-align: center;
  color: #5a9bd8;
  font-family: 'Bebas Neue', sans-serif;
  opacity: 0;
  animation: ${({ isVisible }) => isVisible && fadeInBounce} 1.8s ease-out forwards;

  p {
    font-size: 2rem;
    color: #ffffff;
    margin: 0;
  }

  h1 {
    font-size: ${({ isCenter }) => (isCenter ? '4rem' : '3rem')};
    margin: 0;
  }

  @media (max-width: 768px) {
    p {
      font-size: 2rem;
    }
    h1 {
      font-size: ${({ isCenter }) => (isCenter ? '5rem' : '2rem')};
    }
    ${({ mobilePosition }) => mobilePosition};
  }

  ${({ position }) => position};
`;

// Initialize refs outside the component function to avoid reinitializing on each render
const refs = Array(7).fill().map(() => React.createRef());

const Why = () => {
  const [visibleText, setVisibleText] = useState([false, false, false, false, false, false, false]);
  const observers = useRef([]);

  const phrases = [
    { subtext: "Long Term", mainText: "UTILITY", position: css`top: 15%; left: 20%;`, mobilePosition: css`top: 10%; left: 5%;` },
    { subtext: "DIAMOND HAND", mainText: "COMMUNITY", position: css`top: 15%; right: 20%;`, mobilePosition: css`top: 10%; right: 5%;` },
    { subtext: "Why", mainText: "BLUNANA", position: css`top: 40%; left: 40%; transform: translate(-50%, -50%);`, mobilePosition: css`top: 25%; left: 15%; transform: translate(-50%, -50%);` },
    { subtext: "Viral", mainText: "ART", position: css`top: 60%; left: 15%;`, mobilePosition: css`bottom: 50%; left: 5%;` },
    { subtext: "BASED", mainText: "PARTNERS", position: css`bottom: 0%; left: 25%;`, mobilePosition: css`bottom: 10%; left: 5%;` },
    { subtext: "Trusted", mainText: "TEAM", position: css`bottom: 5%; right: 20%;`, mobilePosition: css`bottom: 30%; right: 5%;` },
    { subtext: "BUILDING ON", mainText: "SOLANA", position: css`bottom: 30%; right: 15%;`, mobilePosition: css`bottom: 10%; right: 5%;` },
  ];

  useEffect(() => {
    observers.current = refs.map((ref, index) => {
      return new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setVisibleText((prev) => {
              const updated = [...prev];
              updated[index] = true;
              return updated;
            });
          }
        },
        { threshold: 0.5 }
      );
    });

    observers.current.forEach((observer, index) => {
      if (refs[index].current) {
        observer.observe(refs[index].current);
      }
    });

    return () => {
      observers.current.forEach((observer) => observer.disconnect());
    };
  }, []);

  return (
    <Container>
      {phrases.map((phrase, index) => (
        <PositionedTextBox
          key={index}
          ref={refs[index]}
          isVisible={visibleText[index]}
          isCenter={index === 2}
          position={phrase.position}
          mobilePosition={phrase.mobilePosition}
        >
          <p>{phrase.subtext}</p>
          <h1>{phrase.mainText}</h1>
        </PositionedTextBox>
      ))}
    </Container>
  );
};

export default Why;

