import React from 'react';
import styled from 'styled-components';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "@fontsource/bebas-neue"; // Importing Bebas Neue font for styling

const TokenomicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 20px;
  color: #5a9bd8;
  font-family: 'Bebas Neue', sans-serif;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;


const TokenTitle = styled.h1`
  font-size: 3rem;
  color: #5a9bd8;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const MainTitle = styled.h1`
  font-size: 4rem;
  color: #5a9bd8;
  text-align: center;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const SubTitle = styled.h2`
  font-size: 2rem;
  color: #FFFFFF;
  margin-top: 10px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 80%;
  max-width: 1200px;
  margin-top: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const DetailBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5a9bd8;

  h2 {
    font-size: 1.5rem;
    color: #ffffff;
  }

  p {
    font-size: 2rem;
    margin: 0;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.2rem;
    }

    p {
      font-size: 1.5rem;
    }
  }
`;

const ContractContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #5a9bd8;
  font-size: 1.5rem;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    flex-direction: column;
    gap: 5px;
  }
`;

const Tokenomics = () => {
  const contractAddress = "C9vfeaCLhJy7sykgKnfzi6RikawQNoGtRKwsaupKavmV";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(contractAddress);
      alert("Contract address copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy contract address:", err);
    }
  };

  return (
    <TokenomicsContainer>
      {/* Top Divider */}

      {/* Top Section */}
      <TokenTitle>Token</TokenTitle>
      <MainTitle>BLUNOMICS</MainTitle>
      <SubTitle>Supply</SubTitle>
      <MainTitle>5BN</MainTitle>

      {/* Contract Address with Copy Button */}
      <ContractContainer>
        <span>{contractAddress}</span>
        <Tooltip title="Copy to clipboard">
          <IconButton onClick={handleCopy} color="primary" size="small">
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </ContractContainer>

      {/* Details Grid */}
      <DetailsGrid>
        <DetailBox>
          <h2>100% LIQUIDITY</h2>
          <p>BURNT</p>
        </DetailBox>
        <DetailBox>
          <h2>DEFLATIONARY</h2>
          <p>SUPPLY</p>
        </DetailBox>
        <DetailBox>
          <h2>TREASURY</h2>
          <p>LOCKED</p>
        </DetailBox>
        <DetailBox>
          <h2>MINT AUTHORITY</h2>
          <p>REVOKED</p>
        </DetailBox>
      </DetailsGrid>

      {/* Bottom Divider */}
    </TokenomicsContainer>
  );
};

export default Tokenomics;
