import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import rescueImage from "../assets/rescue.webp"; // Import the Rescue image

// Define fade-in animation
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  60% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110vh; /* Slightly larger than full screen for desktop */
  width: 100vw;
  overflow: hidden;
  padding-top: 80px; /* Padding to give space above the component */
  padding-left: 40px;

  @media (max-width: 768px) {
    padding-top: 20px;
    padding-left: 0px;
    height: auto; /* Height will adapt to image height */
    padding-bottom: 10px; /* Extra space below on mobile */
  }
`;

const ImageContainer = styled.div`
  opacity: 0;
  animation: ${({ isVisible }) => isVisible && fadeIn} 2.5s ease-out forwards;
  width: 85%;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
    @media (max-width: 768px) {
  width: 95%;
}
`;

const RescueImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 15px;
  display: block;
`;

const Rescue = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Trigger animation only once
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Container ref={ref}>
      <ImageContainer isVisible={isVisible}>
        <RescueImage src={rescueImage} alt="Rescue" />
      </ImageContainer>
    </Container>
  );
};

export default Rescue;

