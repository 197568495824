import React from 'react';
import styled from 'styled-components';
import { FaDiscord } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { Link } from 'react-scroll';
import BannerImage from '../assets/mnk3ybanner.webp';

const Header = () => {
  return (
    <Wrapper id="home">
      <ContentWrapper>
        <ButtonWrapper>
          <StyledButton href="https://x.com/mnk3ylabs" target="_blank" rel="noopener noreferrer">
            <FaTwitter style={{ marginRight: '10px' }} /> Follow
          </StyledButton>
          <StyledButton href="https://discord.com/invite/HYBuc6SRS" target="_blank" rel="noopener noreferrer">
            <FaDiscord style={{ marginRight: '10px' }} /> Join
          </StyledButton>
        </ButtonWrapper>
        <ScrollDown to="allocalc" smooth={true} duration={500}>
          Learn More ↓
        </ScrollDown>
      </ContentWrapper>
    </Wrapper>
  );
};

// Styled Components

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  background-image: url(${BannerImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;

  /* Dark overlay */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); /* Semi-transparent dark overlay */
    z-index: 0;

    /* Darken overlay for mobile */
    @media (max-width: 768px) {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  /* Ensure background is centered and fix vh issues for mobile */
  @media (max-width: 768px) {
    background-position: calc(50% - 150px) center;
    min-height: 100vh;
    background-attachment: scroll; /* Prevent fixed background issues on mobile */
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 300px;

  /* Adjust padding on mobile */
  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;


const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const StyledButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 36px;
  border: 2px solid #fff;
  border-radius: 50px;
  color: #121212;
  background-color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: #121212;
    color: #fff;
    border-color: #121212;
  }

  /* Adjust button size for smaller screens */
  @media (max-width: 480px) {
    font-size: 16px;
    padding: 6px 20px;
  }
`;

const ScrollDown = styled(Link)`
  margin-top: 20px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #83d6f7;
  }

  /* Adjust font size for smaller screens */
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export default Header;

