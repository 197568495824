import React from "react";
import styled from "styled-components";

// Partner images
import angryApes from "../assets/partners/angryapes.webp";
import assetDash from "../assets/partners/assetdash.webp";
import flux from "../assets/partners/flux.webp";
import frens from "../assets/partners/frens.webp";
import helio from "../assets/partners/helio.webp";
import lunarDollz from "../assets/partners/lunardollz.jpg";
import mindao from "../assets/partners/mindao.webp";
import miners from "../assets/partners/miners.jpg";
import mutants from "../assets/partners/mutants.webp";

// Partner links
const partners = [
  { image: flux, name: "Flux", url: "https://fluxinc.io" },
  { image: mindao, name: "MINDao", url: "https://mindao.xyz" },
  { image: helio, name: "Helio", url: "https://hel.io" },
  { image: assetDash, name: "AssetDash", url: "https://assetdash.com" },
  { image: lunarDollz, name: "Lunar Dollz", url: "https://lunardollz.com" },
  { image: frens, name: "Frens", url: "https://frenslabs.xyz" },
  { image: angryApes, name: "Angry Apes", url: "https://angryapes.co" },
  { image: miners, name: "Enchanted Miners", url: "https://x.com/enchanted_nfts" },
  { image: mutants, name: "Mutants", url: "https://x.com/MutantsOnSol" },
];

// Styled components
const PartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 20px;
`;

const Heading = styled.h1`
  color: #5a9bd8;
  font-size: 6rem;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.05em;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
`;

const PartnerLink = styled.a`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    transform: scale(1.05);
  }
`;

const PartnerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Partners = () => {
  return (
    <PartnersContainer>
      <Heading>Partners</Heading>
      <Grid>
        {partners.map((partner) => (
          <PartnerLink
            key={partner.name}
            href={partner.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PartnerImage src={partner.image} alt={partner.name} />
          </PartnerLink>
        ))}
      </Grid>
    </PartnersContainer>
  );
};

export default Partners;

