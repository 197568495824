import React from 'react';
import TopNavbar from './components/TopNavbar';
import Header from './components/Header';
import AllocationCalculator from './components/AlloCalc';
import BlunanaComponent from './components/BlunanaComponent';
import Frame2 from './components/Frame2';
import Trust from './components/Trust';
import Utility from './components/Utility';
import Why from './components/Why';
import Tokenomics from './components/Tokenomics';
import Piechart from './components/Piechart';
import Rescue from './components/Rescue';
import Mnk3y from './components/Mnk3y';
import Partners from './components/Partners';
import './App.css';

function App() {
  return (
    <div className="app">
        <TopNavbar />
        <Header />
        <BlunanaComponent />
        <section className="allocalc">
        <AllocationCalculator />
        </section>
        <Frame2 />
        <Trust />
        <Why />
        <Rescue />
        <Tokenomics />
        <Piechart />
        <Utility />
        <Partners />
        <Mnk3y />
    </div>
  );
}

export default App;


