import React, { useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import styled from 'styled-components';

const data = [
  { name: 'LP Burnt', value: 40 },
  { name: 'MNK3YS Airdrop', value: 15 },
  { name: 'Mnk3ylabs', value: 5 },
  { name: 'Staking', value: 15 },
  { name: 'Treasury', value: 10 },
  { name: 'Partner Airdrop', value: 10 },
  { name: 'Rewards', value: 5 },
];

const COLORS = ['#3c6f99', '#76c7c0', '#69a8c6', '#4b8bc7', '#2d4e6f', '#2d3f5f', '#803545'];

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent;
  overflow: hidden;
  padding: 0;
  width: 100vw;

  /* Shift the chart to the left on mobile */
  @media (max-width: 768px) {
    transform: translateX(-20px); /* Move 30px to the left */
  }
`;

const OutlinedLabel = ({ x, y, name, value, index }) => {
  const isMobile = window.innerWidth < 768;
  const adjustedX = isMobile && name === "LP Burnt" ? x + 20 : x; // Move LP Burnt 20px to the right on mobile

  return (
    <text
      x={adjustedX}
      y={y}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize="14px"
      fill="#ffffff"
      stroke="#000000"
      strokeWidth={0.5}
      style={{
        pointerEvents: 'none',
        visibility: 'visible !important',
      }}
    >
      {`${name} ${value}%`}
    </text>
  );
};

const Piechart = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const isMobile = window.innerWidth < 768;

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(null);
  };

  return (
    <ChartContainer>
      <PieChart width={isMobile ? 350 : 500} height={isMobile ? 350 : 500} style={{ width: '110%' }}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          startAngle={135}
          endAngle={495}
          innerRadius={isMobile ? 50 : 80}
          outerRadius={isMobile ? 130 : 150}
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          dataKey="value"
          animationDuration={300}
          label={({ x, y, name, value, index }) => (
            <OutlinedLabel x={x} y={y} name={name} value={value} index={index} />
          )}
          labelLine={false}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              stroke="#0d0f1a"
              strokeWidth={1}
              outerRadius={activeIndex === index ? (isMobile ? 140 : 160) : isMobile ? 130 : 150}
              style={{
                transition: 'all 0.3s ease',
                filter: activeIndex === index ? 'brightness(1.2)' : 'none',
                pointerEvents: 'none',
              }}
            />
          ))}
        </Pie>
      </PieChart>
    </ChartContainer>
  );
};

export default Piechart;

