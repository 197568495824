import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent, TextField, Button } from '@mui/material';
import styled from 'styled-components';

// Styled Components (as per your original code)
const StyledButton = styled(Button)`
  background-color: #83d6f7 !important;
  color: #121212 !important;
  font-family: 'Anton', sans-serif !important;
  padding: 12px 30px !important;
  border-radius: 50px !important;
  font-size: 20px !important;
  margin-top: 20px !important;
  text-transform: uppercase;
  &:hover {
    background-color: #64d9fb !important;
  }
`;

const ResultText = styled(Typography)`
  font-family: 'Anton', sans-serif !important;
  color: #83d6f7;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 20px;
`;

const StyledTitle = styled(Typography).attrs({ variant: 'h1' })`
  font-family: 'Anton', sans-serif !important;
  font-size: 4rem !important;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
`;

const AllocationCalculator = () => {
  const [mnk3ys, setMnk3ys] = useState('');
  const [blunana, setBlunana] = useState('');
  const [allocation, setAllocation] = useState(null);

  const calculateAllocation = () => {
    const mnk3ysCount = parseInt(mnk3ys) || 0;
    const blunanaCount = parseInt(blunana) || 0;
    let totalNfts = mnk3ysCount + blunanaCount;

    if (totalNfts <= 0) {
      setAllocation(0);
      return;
    }

    let totalAllocation = 0;

    while (totalNfts > 0) {
      let cycleNfts = Math.min(totalNfts, 10);
      totalAllocation += cycleNfts * cycleNfts * 1000;
      totalNfts -= cycleNfts;
    }

    setAllocation(totalAllocation);
  };

  return (
    <Card
      sx={{
        padding: '20px',
        boxShadow: 'none',
        borderRadius: '12px',
        backgroundColor: 'transparent',
      }}
    >
      <CardContent>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <StyledTitle>$BLUNANA Allocation Calculator</StyledTitle>
          </Grid>

          {/* MNK3Ys Owned */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="MNK3Ys Owned"
              type="number"
              value={mnk3ys}
              onChange={(e) => setMnk3ys(e.target.value)}
              variant="outlined"
              InputLabelProps={{ style: { fontFamily: 'Anton', color: '#83d6f7' } }}
              sx={{
                input: { color: '#fff', fontFamily: 'Anton' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#83d6f7' },
                },
              }}
            />
          </Grid>

          {/* Blunana NFTs Owned */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Blunana NFTs Owned"
              type="number"
              value={blunana}
              onChange={(e) => setBlunana(e.target.value)}
              variant="outlined"
              InputLabelProps={{ style: { fontFamily: 'Anton', color: '#83d6f7' } }}
              sx={{
                input: { color: '#fff', fontFamily: 'Anton' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#83d6f7' },
                },
              }}
            />
          </Grid>

          {/* Calculate Button */}
          <Grid item xs={12} textAlign="center">
            <StyledButton onClick={calculateAllocation}>Calculate Allocation</StyledButton>
          </Grid>

          {/* Result Display */}
          {allocation !== null && (
            <Grid item xs={12}>
              <ResultText>
                Your total $BLUNANA allocation is: {allocation.toLocaleString()} $BLUNANA
              </ResultText>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AllocationCalculator;

