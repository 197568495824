import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import "@fontsource/bebas-neue"; // Importing Bebas Neue font

// Define animation for text to slide in from the left
const slideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
`;

const TextBox = styled.div`
  position: absolute;
  color: #5a9bd8;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
  opacity: 0; /* Initially hidden */
  animation: ${({ isVisible }) => isVisible && slideInFromLeft} 1.5s ease forwards;

  p {
    font-size: 2rem;
    color: #FFFFFF; /* White color for subtext */
    margin: 0;
  }

  h1 {
    font-size: ${({ isMiddle }) => (isMiddle ? '6rem' : '4rem')}; /* Larger font for the middle text */
    margin: 0;
  }

  ${({ position }) => position};

  @media (max-width: 768px) {
    p {
      font-size: 2rem;
    }

    h1 {
      font-size: ${({ isMiddle }) => (isMiddle ? '3.5rem' : '2.5rem')};
    }
  }
`;

// Define desktop and mobile positions
const positions = [
  css`top: 20%; left: 25%;`,
  css`top: 10%; right: 25%;`,
  css`top: 35%; left: 45%; transform: translate(-50%, -50%);`,
  css`bottom: 20%; left: 20%;`,
  css`bottom: 30%; right: 30%;`,
];

const mobilePositions = [
  css`top: 25%; left: 5%;`,
  css`top: 20%; right: 15%;`,
  css`top: 40%; left: 40%; transform: translate(-50%, -20%);`,
  css`bottom: 25%; left: 5%;`,
  css`bottom: 20%; right: 15%;`,
];

// Initialize refs outside the component function to keep them stable
const refs = Array(5).fill().map(() => React.createRef());

const Frame2 = () => {
  const [visibleText, setVisibleText] = useState([false, false, false, false, false]);
  const observers = useRef([]);

  const phrases = [
    { subtext: "Worthless", mainText: "MEMES" },
    { subtext: "Endless", mainText: "SCAMS" },
    { subtext: "Tired of", mainText: "RUGS" },
    { subtext: "Empty", mainText: "PROMISES" },
    { subtext: "Invisible", mainText: "DEVS" },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    observers.current = refs.map((ref, index) => {
      return new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setVisibleText((prev) => {
              const updated = [...prev];
              updated[index] = true;
              return updated;
            });
          }
        },
        { threshold: 0.5 }
      );
    });

    observers.current.forEach((observer, index) => {
      if (refs[index].current) {
        observer.observe(refs[index].current);
      }
    });

    return () => {
      observers.current.forEach((observer) => observer.disconnect());
    };
  }, []);

  return (
    <Container>
      {phrases.map((phrase, index) => (
        <TextBox
          key={index}
          ref={refs[index]}
          isVisible={visibleText[index]}
          position={isMobile ? mobilePositions[index] : positions[index]}
          isMiddle={index === 2}
        >
          <p>{phrase.subtext}</p>
          <h1>{phrase.mainText}</h1>
        </TextBox>
      ))}
    </Container>
  );
};

export default Frame2;

