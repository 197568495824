import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import "@fontsource/bebas-neue"; // Importing Bebas Neue font
import logo from "../assets/logo/logo.webp"; // Import the logo directly

// Define fade-in animation for text
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const TextContainer = styled.div`
  text-align: center;
  opacity: 0;
  animation: ${({ isVisible }) => isVisible && fadeIn} 1.5s ease forwards;
`;

const Subtext = styled.p`
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.1em;
  font-family: 'Bebas Neue', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const MainText = styled.h1`
  color: #5a9bd8;
  font-size: 8rem;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.05em;
  font-family: 'Bebas Neue', sans-serif;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`;

const LogoImage = styled.img`
  width: 400px;
  height: auto;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 350px;
  }
`;

const Trust = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Trigger animation only once
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Container ref={ref}>
      <TextContainer isVisible={isVisible}>
        <Subtext>FROM A TEAM YOU CAN</Subtext>
        <MainText>TRUST</MainText>
      </TextContainer>
      <LogoImage src={logo} alt="Trust Logo" />
    </Container>
  );
};

export default Trust;

